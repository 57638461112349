import React, { useState, useEffect, useCallback } from "react";

interface PopupProps {
  message: string;
  type: string;
  onClose: () => void;
}

const Popup: React.FC<PopupProps> = ({ message, type, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);

  const onShowMessage = useCallback(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      onClose();
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [onClose]);

  useEffect(() => {
    onShowMessage();
  }, [onShowMessage]);

  const typeToColor: { [key: string]: string } = {
    success: "bg-green-500",
    error: "bg-red-500",
    info: "bg-blue-500",
  };

  return (
    <div
      className={`${isVisible ? "opacity-75" : "opacity-0"} ${
        typeToColor[type]
      } mb-4 px-4 py-2 text-white rounded font-bold transition-opacity transition-transform`}
    >
      {message}
    </div>
  );
};

export default Popup;
