import React, { useState, useEffect } from "react";
import { API } from "../const/const";
import axiosService from "../utils/axios";
import TableComponent from "../components/TableComponent";
import { useMessage } from "../utils/message";
type Result = {
  id: number;
  campaign: string;
  phone: string;
  is_sync: boolean;
  external_service: string;
  direction: string;
  question: string | null;
  scenario: string | null;
};
const ResultsNotSync: React.FC = () => {
  const [results, setResults] = useState<Result[]>([]);
  const { errorMessage } = useMessage();

  useEffect(() => {
    axiosService
      .get(`${API}/results_not_sync`)
      .then((response) => {
        setResults(response.data);
      })
      .catch((error) => errorMessage(`Results not sync with error:  ${error}`));
  }, [errorMessage]);

  return (
    <div>
      <TableComponent
        search="phone"
        data={results}
        columns={[
          {
            key: "id",
            name: "Id",
          },
          {
            key: "campaign",
            name: "Kampania",
          },
          {
            key: "phone",
            name: "Telefon",
          },
          {
            key: "external_service",
            name: "Serwis Zewnętrzny",
          },
        ]}
      />
    </div>
  );
};
export default ResultsNotSync;
