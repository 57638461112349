import React, { useState, useEffect, useCallback } from "react";
import axiosService from "../utils/axios";
import { API } from "../const/const";
import { Candidate } from "../types/types";
import LinkComponent from "../components/LinkComponent";
import { useMessage } from "../utils/message";
import { useParams } from "react-router";
import UploadFileComponent from "../components/UploadFileComponent";
import PaginationFilterTableComponent, {
  PaginationColumn,
  PaginationColumnType,
} from "../components/PaginationFilterTableComponent";
import ModalDeleteComponent from "../components/ModalDeleteComponent";

type Header = {
  id: number;
  name: string;
  phone: string;
  status: string;
  dialog_status: string;
  buttons: any;
};

const header: PaginationColumn<Header>[] = [
  { name: "Id", key: "id", withSearching: true, withSorting: true },
  { name: "Nazwa", key: "name", withSearching: true, withSorting: true },
  { name: "Telefon", key: "phone", withSearching: true, withSorting: true },
  { name: "Status", key: "status", withSearching: true, withSorting: true },
  {
    name: "Dialog Status",
    key: "dialog_status",
    withSearching: true,
    withSorting: true,
  },
  {
    name: "",
    key: "buttons",
    withSearching: false,
    withSorting: false,
    type: PaginationColumnType.ELEMENT,
  },
];

const ITEM_ON_PAGE: number = 30;

const CandidateList: React.FC = () => {
  const { successMessage, errorMessage } = useMessage();
  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const [count, setCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { id } = useParams();

  const getCandidates = useCallback(
    (page: number) => {
      if (id) {
        setCurrentPage(page);
        axiosService
          .get(
            `${API}/get_campaign_candidates/${id}/?page=${page}&page_size=${ITEM_ON_PAGE}`
          )
          .then((response) => {
            setCandidates(response.data.results);
            setCount(response.data.count);
          })
          .catch((error) =>
            errorMessage(
              `An error occurred while retrieving campaign candidates: ${error}`
            )
          );
      }
    },
    [errorMessage, id]
  );

  useEffect(() => {
    getCandidates(currentPage);
  }, [getCandidates, currentPage]);

  const handleRemoveCandidate = (idx: number) => {
    axiosService
      .delete(`${API}/candidates/${idx}/`)
      .then(() => {
        getCandidates(1);
        successMessage("Candidate removal was successful");
      })
      .catch((error) =>
        errorMessage(`An error occurred while deleting the candidate: ${error}`)
      );
  };

  const handleUpload = (selectedFile: any) => {
    const fileData = new FormData();
    fileData.append("file", selectedFile);
    axiosService
      .post(`${API}/upload_candidates/${id}/`, fileData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        successMessage("Upload was successful");
        getCandidates(1);
      })
      .catch((error) => {
        errorMessage(
          `An error occurred while uploading the candidates: ${error}`
        );
      });
  };

  const addButtons = (list: Candidate[]): Header[] =>
    list.map((item) => ({
      ...item,
      buttons: (
        <div className=" flex">
          <LinkComponent
            text="Edycja"
            url={`/campaign/${id}/candidate/${item.id}`}
          />
          <ModalDeleteComponent
            name={`Kandydat: ${item.name}`}
            buttonComponent={<LinkComponent text="Usuń" />}
            onDelete={() => handleRemoveCandidate(item.id)}
          />
        </div>
      ),
    }));

  return (
    <div>
      <h1 className="font-bold text-3xl pb-3">Lista kandydatów</h1>
      <div>
        <UploadFileComponent
          types={".csv"}
          text="Dodaj kandydatów przez plik csv"
          buttonText="Załaduj plik"
          callback={handleUpload}
        />
        <div className="pb-6">
          <LinkComponent
            text="Dodaj pojedynczego kandydata"
            url={`/campaign/${id}/candidate/new`}
          />
        </div>
      </div>
      <PaginationFilterTableComponent
        header={header}
        data={{ data: addButtons(candidates), count }}
        currentPage={currentPage}
        itemOnPage={ITEM_ON_PAGE}
        onPageChange={(num) => setCurrentPage(num)}
      />
    </div>
  );
};

export default CandidateList;
