import React from "react";
import { Route, Routes } from "react-router-dom";
import CampaignForm from "../screens/CampaignForm";
import CampaignList from "../screens/CampaignList";
import Home from "../screens/Home";
import ScenarioCreator from "../screens/ScenarioCreator";
import ScenarioForm from "../screens/ScenarioForm";
import ScenarioTree from "../screens/ScenarioTree";
import Users from "../screens/Users";
import UserForm from "../screens/UserForm";
import ResetPassword from "../screens/ResetPassword";
import PhoneList from "../screens/PhoneList";
import Result from "../screens/Result";
import CandidateForm from "../screens/CandidateForm";
import CandidateList from "../screens/CandidateList";
import TagList from "../screens/TagList";
import TagForm from "../screens/TagForm";
import PhoneForm from "../screens/PhoneForm";
import InvalidActionList from "../screens/InvalidActionList";
import InvalidActionForm from "../screens/InvalidActionForm";
import InternetConnectionForm from "../screens/InternetConnection";
import UnsignedCandidateList from "../screens/UnsignedCandidateList";
import TemplateScenarioList from "../screens/TemplateScenarioList";
import TemplateScenarioForm from "../screens/TemplateScenarioForm";
import ResultsNotSync from "../screens/ResultsNotSync";

export default function MainContent() {
  return (
    <main className="p-4 md:ml-64 h-auto">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/users" element={<Users />} />
        <Route path="/results_not_sync" element={<ResultsNotSync />} />
        <Route path="/user/new" element={<UserForm />} />
        <Route path="/campaigns" element={<CampaignList />} />
        <Route path="/template_scenarios" element={<TemplateScenarioList />} />
        <Route path="/campaign/new" element={<CampaignForm />} />
        <Route path="/campaign/:id" element={<CampaignForm />} />
        <Route
          path="/template_scenarios/:id"
          element={<TemplateScenarioForm />}
        />
        <Route path="/scenario/new/:campaign" element={<ScenarioForm />} />
        <Route path="/scenario/:id/:campaign" element={<ScenarioForm />} />
        <Route path="/scenario_tree/:id" element={<ScenarioTree />} />
        <Route
          path="/scenario_creator/:id/:campaign"
          element={<ScenarioCreator />}
        />
        <Route path="/reset_password/:id" element={<ResetPassword />} />
        <Route path="/phones" element={<PhoneList />} />
        <Route path="/phone/new" element={<PhoneForm />} />
        <Route path="/phone/:id" element={<PhoneForm />} />
        <Route path="/invalid_actions" element={<InvalidActionList />} />
        <Route path="/invalid_action/:id" element={<InvalidActionForm />} />
        <Route path="/invalid_action/new" element={<InvalidActionForm />} />
        <Route path="/result" element={<Result />} />
        <Route path="/connection" element={<InternetConnectionForm />} />
        <Route path="/campaign/:id/candidate/new" element={<CandidateForm />} />
        <Route
          path="/campaign/:id/candidate/:candidate_id"
          element={<CandidateForm />}
        />
        <Route path="/campaign/:id/candidates" element={<CandidateList />} />
        <Route path="/tags" element={<TagList />} />
        <Route path="/tag/new" element={<TagForm />} />
        <Route path="/tag/:id" element={<TagForm />} />
        <Route
          path="/unsigned_candidates"
          element={<UnsignedCandidateList />}
        />
      </Routes>
    </main>
  );
}
