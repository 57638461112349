import React, { useState, useEffect } from "react";
import axiosService from "../utils/axios";
import { API } from "../const/const";
import { InternetConnection } from "../types/types";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import { Spinner } from "flowbite-react";

type ChartData = {
  options: {
    xaxis: {
      tickAmount: number;
    };
  };
  series: {
    name: string;
    data: { x: string; y: number; index: number }[];
  }[];
};

const InternetConnectionForm: React.FC = () => {
  const defaultStartDate = (): Date => {
    const _startDate = new Date();
    _startDate.setDate(_startDate.getDate() - 7);
    return _startDate;
  };

  const [chartData, setChartData] = useState<ChartData>();
  const [startDate, setStartDate] = useState<Date>(defaultStartDate());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [isLoader, setIsLoader] = useState<Boolean>(true);

  const handleStartDataChange = (event: any) => {
    setStartDate(new Date(`${event.target.value}T00:00:00.0Z`));
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(new Date(`${event.target.value}T23:59:59.0Z`));
  };

  const dateTimeToString = (date: Date): string =>
    date.toISOString().split("Z")[0];
  const dateToString = (date: Date): string => date.toISOString().split("T")[0];

  useEffect(() => {
    axiosService
      .get(
        `${API}/internet_result/?start_date=${dateTimeToString(
          startDate ?? defaultStartDate()
        )}&end_date=${dateTimeToString(endDate ?? new Date())}&periods=${200}`
      )
      .then((response) => {
        setChartData(transformDataForChart(response.data));
        setIsLoader(false);
      })
      .catch((error) => console.log(error));
  }, [startDate, endDate]);

  const transformDataForChart = (data: InternetConnection[]) => {
    const formattedData = data.map((entry) => ({
      x: moment.utc(entry.created_at).format("YYYY-MM-DD HH:mm:ss"),
      y: entry.negative_pings,
      index: entry.id,
    }));

    return {
      options: {
        xaxis: {
          tickAmount: 20,
        },
      },
      series: [
        {
          name: "Ilośc negatywnych pingów",
          data: formattedData,
        },
      ],
    };
  };

  return (
    <div>
      <div className="mb-12">
        <h1>Zakres wyświetlania</h1>
        <div className="flex">
          <div className="flex items-center">
            <h1 className="pr-2">Od</h1>
            <input
              type="date"
              value={startDate ? dateToString(startDate) : ""}
              onChange={handleStartDataChange}
            />
          </div>
          <div className="flex items-center">
            <h1 className="px-2">Do</h1>
            <input
              type="date"
              value={endDate ? dateToString(endDate) : ""}
              onChange={handleEndDateChange}
            />
          </div>
        </div>
      </div>
      {isLoader ? (
        <div className="flex items-center">
          <h1 className="pr-6 text-lg">Pobieranie danych</h1>
          <Spinner aria-label="Extra large spinner example" size="xl" />
        </div>
      ) : (
        <ReactApexChart
          options={chartData?.options}
          series={chartData?.series}
          type="line"
          height={400}
        />
      )}
    </div>
  );
};

export default InternetConnectionForm;
