import React from "react";
import Popup from "../components/Popup";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { useMessage } from "../utils/message";

const Message: React.FC = () => {
  const messages = useSelector((state: RootState) => state.messages.messages);
  const { removeById } = useMessage();

  return (
    <div className="message-stack fixed bottom-5 right-5 w-96 flex flex-col-reverse z-50">
      {messages.map((msg) => (
        <Popup
          key={msg.id}
          message={msg.message}
          type={msg.type}
          onClose={() => removeById(msg.id)}
        />
      ))}
    </div>
  );
};

export default Message;
