import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { addMessage, removeMessageById } from "../store/slices/popup";

export const useMessage = () => {
  const dispatch = useDispatch();

  const infoMessage = useMemo(
    () => (message: string) => {
      dispatch(
        addMessage({
          id: Date.now(),
          message,
          type: "info",
        })
      );
    },
    [dispatch]
  );

  const successMessage = useMemo(
    () => (message: string) => {
      dispatch(
        addMessage({
          id: Date.now(),
          message,
          type: "success",
        })
      );
    },
    [dispatch]
  );

  const errorMessage = useMemo(
    () => (message: string) => {
      dispatch(
        addMessage({
          id: Date.now(),
          message,
          type: "error",
        })
      );
    },
    [dispatch]
  );

  const removeById = useMemo(
    () => (id: number) => {
      dispatch(removeMessageById(id));
    },
    [dispatch]
  );

  return { infoMessage, successMessage, errorMessage, removeById };
};
