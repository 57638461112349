import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import axiosService from "../utils/axios";
import { API } from "../const/const";
import { User } from "../types/types";
import FormComponent from "../components/FormComponent";
import { InputType } from "../const/Status";
import { useMessage } from "../utils/message";

const ResetPassword: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);
  const { successMessage, errorMessage } = useMessage();

  const handleReset = (formData: any) => {
    if (formData.password1 !== formData.password2) {
      errorMessage("Passwords are not the same");
    } else {
      axiosService
        .post(`${API}/reset_password/`, {
          user_id: id,
          new_password: formData.password1,
        })
        .then(() => {
          successMessage("Succesfully change password");
          navigate("/users");
        })
        .catch((err) => {
          errorMessage(`Reset password with error: ${err}`);
        });
    }
  };

  useEffect(() => {
    axiosService
      .get(`${API}/users/${id}/`)
      .then((response) => {
        setUser(response.data);
      })
      .catch((err) => {
        console.log(`Get user data with error: ${err}`);
      });
  }, [id]);

  return (
    <div>
      <div className="bg-gray-100 p-6">
        <h1 className="font-bold text-3xl pb-6">Resetowanie hasła</h1>
        <h1 className="font-bold text-xl pb-3">{`Użytkownik: ${user?.username}`}</h1>
        <h1 className="font-bold text-xl pb-6">{`Email: ${user?.email}`}</h1>
        <FormComponent
          onSave={handleReset}
          buttonName={"Zresetuj"}
          fields={[
            {
              name: "password1",
              label: "Podaj nowe hasło",
              placeholder: "hasło",
              require: true,
              type: InputType.PASSWORD,
              value: "",
            },
            {
              name: "password2",
              label: "Powtórz hasło",
              placeholder: "powtórz",
              require: true,
              type: InputType.PASSWORD,
              value: "",
            },
          ]}
        />
      </div>
    </div>
  );
};

export default ResetPassword;
