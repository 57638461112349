import { useNavigate } from "react-router-dom";
import { CandidateStats } from "../types/types";
import ButtonComponent from "./ButtonComponent";

interface ICandidateStats {
  campaignId: string | undefined;
  data?: CandidateStats;
}

const CandidateStatsComponent = (props: ICandidateStats) => {
  const navigate = useNavigate();

  return (
    <div className="bg-gray-200 w-1/3 rounded min-w-[500px]">
      <div className="p-2">
        <h1 className="text-lg font-bold">STATYSTYKI KANDYDATÓW</h1>
        <div className="flex">
          <h1 className="w-1/3">Ilość kandydatów:</h1>
          <h1>{props.data?.candidates_count}</h1>
        </div>
        <h1 className="w-1/3">W tym:</h1>
        {props.data?.count_status &&
          Object.entries(props.data.count_status).map(([status, count]) => (
            <div key={status} className="flex pl-6">
              <h1 className="w-2/5"> {`Status ${status}`}</h1>
              <h1>{count}</h1>
            </div>
          ))}

        <div className="pt-4">
          <ButtonComponent
            callback={() =>
              navigate(`/campaign/${props.campaignId}/candidates`)
            }
            text="Lista kandydatów"
          />
        </div>
      </div>
    </div>
  );
};

export default CandidateStatsComponent;
