import ButtonComponent from "./ButtonComponent";
import { JSXElementConstructor, ReactElement, useState } from "react";
import { Modal } from "flowbite-react";
import React from "react";

interface IModalDeleteComponent {
  onDelete(): void;
  buttonComponent: ReactElement<any, string | JSXElementConstructor<any>>;
  name?: string;
}

const ModalDeleteComponent = (props: IModalDeleteComponent) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div>
      {React.cloneElement(props.buttonComponent, {
        callback: () => setOpenModal(true),
      })}

      <Modal size="sm" show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header>Usunąć?</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              {props.name}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonComponent
            text="Tak"
            callback={() => {
              setOpenModal(false);
              props.onDelete();
            }}
          />
          <ButtonComponent text="Nie" callback={() => setOpenModal(false)} />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalDeleteComponent;
