import {
  BiSolidDashboard,
  BiSolidSpreadsheet,
  BiSolidUser,
  BiStats,
  BiSolidPhone,
  BiHash,
} from "react-icons/bi";

import { MenuItem, Role } from "../../types/types";

export const menuItemsData: MenuItem[] = [
  {
    href: "/",
    icon: BiSolidDashboard,
    label: "Przegląd",
    permission: [Role.SuperAdmin, Role.Admin, Role.User],
  },
  {
    icon: BiSolidSpreadsheet,
    label: "Kampanie",
    subItems: [
      { href: "/campaigns", label: "Lista kampanii" },
      { href: "/campaign/new", label: "Dodaj kampanię" },
      { href: "/invalid_actions", label: "Lista złych akcji" },
      { href: "/invalid_action/new", label: "Dodaj złą akcję" },
      { href: "/template_scenarios", label: "Templatki scenariuszy" },
    ],
    permission: [Role.SuperAdmin, Role.Admin, Role.User],
  },
  {
    icon: BiStats,
    label: "Analityka",
    subItems: [
      { href: "/result", label: "Rezultaty" },
      { href: "/connection", label: "Połączenie" },
      { href: "/results_not_sync", label: "Niezsynchro. rezultaty" },
    ],
    permission: [Role.SuperAdmin, Role.Admin],
  },
  {
    icon: BiSolidUser,
    label: "Użytkownicy",
    subItems: [
      { href: "/users", label: "Lista użytkowników" },
      { href: "/user/new", label: "Dodaj użytkownika" },
      { href: "/unsigned_candidates", label: "Kandydaci bez kampanii" },
    ],
    permission: [Role.SuperAdmin],
  },
  {
    icon: BiSolidPhone,
    label: "Telefony",
    subItems: [
      { href: "/phones", label: "Lista telefonów" },
      { href: "/phone/new", label: "Dodaj telefon" },
    ],
    permission: [Role.SuperAdmin],
  },
  {
    icon: BiHash,
    label: "Tagi",
    subItems: [
      { href: "/tags", label: "Lista tagów" },
      { href: "/tag/new", label: "Dodaj tag" },
    ],
    permission: [Role.SuperAdmin, Role.Admin, Role.User],
  },
];
