import React from "react";
import { Pagination } from "flowbite-react";
import FilterTableComponent, {
  Column,
  HeaderType,
  ColumnType,
} from "./FilterTableComponent";
import { PaginationData } from "../types/types";

type PaginationFilterTableProps<T> = {
  header: Column<T>[];
  data: PaginationData<T>;
  currentPage: number;
  itemOnPage: number;
  onPageChange(page: number): void;
};

export type PaginationColumn<T> = Column<T>;

export const PaginationColumnType = ColumnType;

const PaginationFilterTableComponent = <T extends HeaderType>({
  header,
  data,
  currentPage,
  itemOnPage,
  onPageChange,
}: PaginationFilterTableProps<T>) => {
  const totalPages = Math.floor((data.count - 1) / itemOnPage) + 1;
  return (
    <>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
      <FilterTableComponent data={data.data} header={header} />
    </>
  );
};

export default PaginationFilterTableComponent;
