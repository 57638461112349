import React, { useState, useEffect } from "react";
import axiosService from "../utils/axios";
import { useMessage } from "../utils/message";

import { API } from "../const/const";
import { Phone } from "../types/types";
import PhoneDetailsComponent from "../components/PhoneDetailsComponent";
import ButtonComponent from "../components/ButtonComponent";
import { Link } from "react-router-dom";

const PhoneList: React.FC = () => {
  const [phones, setPhones] = useState<Phone[]>([]);
  const { successMessage, infoMessage, errorMessage } = useMessage();

  useEffect(() => {
    infoMessage("Started getting a list of phone numbers");
    axiosService
      .get(`${API}/phones/`)
      .then((response) => {
        successMessage("Phone list received");
        setPhones(response.data);
      })
      .catch((error) =>
        errorMessage(
          `An error occurred while retrieving the phone list: ${error}`
        )
      );
  }, [successMessage, infoMessage, errorMessage]);

  const handleDelete = (id: number) => {
    axiosService
      .delete(`${API}/phones/${id}/`)
      .then((response) => {
        setPhones(phones.filter((phone) => phone.id !== id));
        successMessage("Phone deleted successfully");
      })
      .catch((error) =>
        errorMessage(`An error occurred during deletion the phone: ${error}`)
      );
  };

  return (
    <div>
      <h1 className="font-bold text-3xl pb-3">Lista telefonów</h1>
      <div className="pb-6">
        <Link to="/phone/new">
          <ButtonComponent text="Dodaj telefon" />
        </Link>
      </div>
      <div className="flex flex-wrap gap-8">
        {phones.map((item) => (
          <PhoneDetailsComponent
            key={item.id}
            data={item}
            onDelete={() => handleDelete(item.id)}
            editLink={`/phone/${item.id}`}
          />
        ))}
      </div>
    </div>
  );
};

export default PhoneList;
