import React, { useState } from "react";
import { FileInput } from "flowbite-react";
import ButtonComponent from "./ButtonComponent";

interface IUploadFile {
  types: string;
  callback(value: any): void;
  buttonText: string;
  text?: string;
  className?: string;
}

const UploadFileComponent = (props: IUploadFile) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [index, setIndex] = useState(0);

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  return (
    <div id="fileUpload" className={props.className ?? "w-full flex m-3"}>
      <div>
        <FileInput
          id="file"
          helperText={props.text ?? ""}
          accept={props.types}
          onChange={handleFileChange}
          key={index}
        />
      </div>
      <ButtonComponent
        className="ml-10"
        callback={() => {
          props.callback(selectedFile);
          setIndex((prev) => prev + 1);
          setSelectedFile(null);
        }}
        text={props.buttonText}
        disabled={!selectedFile}
      />
    </div>
  );
};

export default UploadFileComponent;
