import React, { useState, useEffect } from "react";
import axiosService from "../utils/axios";
import { API } from "../const/const";
import { TScenario } from "../types/types";
import TableComponent from "../components/TableComponent";
import LinkComponent from "../components/LinkComponent";
import { useMessage } from "../utils/message";
import ModalDeleteComponent from "../components/ModalDeleteComponent";

const TemplateScenarioList: React.FC = () => {
  const [templatesData, setTemplatesData] = useState<TScenario[]>([]);
  const { successMessage, errorMessage } = useMessage();

  useEffect(() => {
    axiosService
      .get(`${API}/template_scenarios/`)
      .then((response) => {
        setTemplatesData(response.data);
        successMessage("Successfully received list of templates");
      })
      .catch((error) =>
        errorMessage(
          `An error occurred while retrieving templates list: ${error}`
        )
      );
  }, [errorMessage, successMessage]);

  const handleDelete = (id: number) => {
    axiosService
      .delete(`${API}/template_scenarios/${id}/`)
      .then((response) => {
        setTemplatesData(
          templatesData.filter((template) => template.id !== id)
        );
        successMessage("Template deleted successfully");
      })
      .catch((error) =>
        errorMessage(`An error occurred while deleting the template.: ${error}`)
      );
  };

  return (
    <div>
      <TableComponent
        search="name"
        data={templatesData}
        columns={[
          {
            key: "name",
            name: "Nazwa",
            className: "w-2/3",
          },
          {
            key: "external_service",
            name: "External service",
            className: "w-1/3",
          },
          {
            key: "created_at",
            name: "created At",
            className: "w-1/3",
          },
          {
            key: "buttons",
            name: "",
            onRender: (id: number) => (
              <div className="flex justify-end">
                <LinkComponent
                  text="Edycja"
                  url={`/template_scenarios/${id}`}
                />
                <ModalDeleteComponent
                  name={`Templatka: ${
                    templatesData.find((template) => template.id === id)?.name
                  }`}
                  buttonComponent={<LinkComponent text="Usuń" />}
                  onDelete={() => handleDelete(id)}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default TemplateScenarioList;
