import React, { useState, useEffect } from "react";
import axiosService from "../utils/axios";
import { useParams, useNavigate } from "react-router-dom";
import { InputType, EnumWithId, FindElementWithId } from "../const/Status";
import { API } from "../const/const";
import { TScenario, Phone, ExternalService } from "../types/types";
import FormComponent from "../components/FormComponent";
import { useMessage } from "../utils/message";

const TemplateScenarioForm: React.FC = () => {
  const [templateData, setTemplateData] = useState<TScenario>();
  const [phones, setPhones] = useState<Phone[]>([]);

  const { id } = useParams();
  const navigate = useNavigate();
  const { errorMessage, successMessage } = useMessage();

  useEffect(() => {
    axiosService
      .get(`${API}/phones`)
      .then((res) => {
        setPhones(res.data);
      })
      .catch((error) =>
        errorMessage(`An error occurred while receiving phone list: ${error}`)
      );

    if (id) {
      axiosService
        .get(`${API}/template_scenarios/${id}/`)
        .then((res) => {
          setTemplateData(res.data);
        })
        .catch((error) =>
          errorMessage(
            `An error occurred while retrieving the template: ${error}`
          )
        );
    }
  }, [id, errorMessage]);

  const handleSubmit = (formData: any) => {
    const templateName = {
      name: formData.TemplateName,
      phones: formData.TemplatePhones,
      external_service: formData.TemplateExternalService.name,
    };

    axiosService
      .put(`${API}/template_scenarios/${id}/`, templateName)
      .then(() => {
        navigate(`/template_scenarios`);
        successMessage("Successfully update template name");
      })
      .catch((error) =>
        errorMessage(`An error occurred while update the template: ${error}`)
      );
  };

  return (
    <div className="bg-gray-100 p-6">
      <FormComponent
        onSave={handleSubmit}
        buttonName={"Zapisz zmiany"}
        fields={[
          {
            name: "TemplateName",
            label: "Nazwa templatki",
            placeholder: "tu wpisz nazwę templatki",
            require: true,
            type: InputType.TEXT,
            value: templateData?.name,
          },
          {
            name: "TemplatePhones",
            label: "Telefony dzwoniące",
            type: InputType.TAG,
            options: phones.map(({ id, name }) => ({ id, name })),
            value: templateData?.phones ? templateData.phones : [],
          },
          {
            name: "TemplateExternalService",
            label: "Usługa",
            type: InputType.DROPDOWN,
            options: EnumWithId(ExternalService),
            value: FindElementWithId(
              ExternalService,
              templateData
                ? templateData.external_service
                : ExternalService.NONE
            ),
          },
        ]}
      />
    </div>
  );
};

export default TemplateScenarioForm;
