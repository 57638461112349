import React, { useState, useEffect } from "react";
import { Badge, Table } from "flowbite-react";
import { IoCaretDown, IoCaretUp } from "react-icons/io5";

export const ColumnType = {
  BADGE: "badge",
  ELEMENT: "element",
};

export interface HeaderType extends Record<string, any> {
  id: number;
}

export type Column<T> = {
  name: string;
  key: keyof T;
  withSearching: boolean;
  withSorting: boolean;
  type?: string;
};

type FilterTableProps<T> = {
  header: Column<T>[];
  data: T[];
};

const FilterTableComponent = <T extends HeaderType>({
  header,
  data,
}: FilterTableProps<T>) => {
  const [sortData, setSortData] = useState<T[]>(data);
  const [sortDirection, setSortDirection] = useState<boolean[]>(
    Array.from({ length: header.length }, () => true)
  );
  const [filter, setFilter] = useState<string[]>(
    Array.from({ length: header.length }, () => "")
  );

  const getSortDirection = (direction: boolean): JSX.Element =>
    direction ? <IoCaretDown /> : <IoCaretUp />;

  const sort = (index: number, directValue: number) => {
    const newData: T[] = data.sort((a, b) => {
      const colName: keyof T = header[index].key;
      const valueA = a[colName];
      const valueB = b[colName];
      if (valueA < valueB) {
        return -1 * directValue;
      }
      if (valueA > valueB) {
        return 1 * directValue;
      }
      return 0;
    });
    setSortData(newData);
  };

  const filterData = (currentData: T[], currentFilter: string[]): T[] =>
    currentData.filter((row) =>
      header.every((value, index) =>
        row[value.key]
          .toString()
          .toLowerCase()
          .includes(currentFilter[index].toLowerCase())
      )
    );

  const onHeader = (index: number) => {
    sort(index, sortDirection[index] ? 1 : -1);
    setSortDirection((prev) => {
      let _prev = { ...prev };
      _prev[index] = !prev[index];
      return _prev;
    });
  };

  const onSearch = (text: string, index: number) => {
    let newFilter = { ...filter };
    newFilter[index] = text;
    setFilter(newFilter);
  };

  useEffect(() => {
    setSortData(data);
  }, [data]);

  const renderItem = (value: T, type: string): any => {
    switch (type) {
      case ColumnType.BADGE:
        return (
          <div className="flex flex-wrap gap-2">
            {value.map((tag: string) => (
              <Badge color="info" key={tag}>
                {tag}
              </Badge>
            ))}
          </div>
        );
      case ColumnType.ELEMENT:
        return value;
      default:
        return value.toString();
    }
  };

  return (
    <Table hoverable>
      <Table.Head>
        {header.map((h, index) => (
          <Table.HeadCell key={h.key.toString()}>
            <div
              className="flex"
              onClick={() => h.withSorting && onHeader(index)}
            >
              {h.name}
              {h.withSorting && getSortDirection(sortDirection[index])}
            </div>
            {h.withSearching && (
              <input
                type="search"
                placeholder="Search"
                onChange={(e) => onSearch(e.target.value, index)}
              />
            )}
          </Table.HeadCell>
        ))}
      </Table.Head>
      <Table.Body className="divide-y">
        {filterData(sortData, filter).map((item) => (
          <Table.Row key={item.id}>
            {header.map((h) => (
              <Table.Cell key={h.key.toString()}>
                {renderItem(item[h.key as keyof T], h.type ?? "")}
              </Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default FilterTableComponent;
