import React from "react";
import { useNavigate } from "react-router-dom";
import axiosService from "../utils/axios";
import { API } from "../const/const";
import { Role } from "../types/types";
import FormComponent from "../components/FormComponent";
import { InputType } from "../const/Status";
import { toBool } from "../const/Boolean";

const initValues = {
  role: Role.User,
  isActive: true,
  isStaff: true,
};

const UserForm: React.FC = () => {
  const navigate = useNavigate();
  const handleSubmit = (data: any) => {
    if (
      data.username.trim() === "" ||
      data.email.trim === "" ||
      data.password.trim === "" ||
      data.password !== data.password2
    )
      return;
    const formData = {
      username: data.username,
      email: data.email,
      password: data.password,
      role: data.role.name,
      is_active: toBool(data.isActive),
      is_staff: toBool(data.isStaff),
    };
    axiosService
      .post(`${API}/users/`, formData)
      .then(() => {
        console.log("User is created");
        navigate("/users");
      })
      .catch((err) => {
        console.log(`User is created with error: ${err}`);
      });
  };

  return (
    <div className="bg-gray-100 p-6">
      <FormComponent
        onSave={handleSubmit}
        buttonName={"Dodaj"}
        fields={[
          {
            name: "username",
            label: "Nazwa użytkownika",
            placeholder: "Wpisz nazwę użytkownika",
            require: true,
            type: InputType.TEXT,
            value: "",
          },
          {
            name: "email",
            label: "Email",
            placeholder: "Wpisz email użytkownika",
            require: true,
            type: InputType.TEXT,
            value: "",
          },
          {
            name: "password",
            label: "Hasło",
            placeholder: "Wpisz hasło",
            require: true,
            type: InputType.PASSWORD,
            value: "",
          },
          {
            name: "password2",
            label: "Potwierdzenie hasła",
            placeholder: "Potwierdź hasło",
            require: true,
            type: InputType.PASSWORD,
            value: "",
          },
          {
            name: "role",
            label: "Rola",
            placeholder: "rola",
            require: true,
            type: InputType.DROPDOWN,
            options: (Object.keys(Role) as Array<keyof typeof Role>).map(
              (key, index) => ({
                id: index,
                name: Role[key],
              })
            ),
            value: {
              id: Object.keys(Role).findIndex((e) => initValues.role === e),
              name: initValues.role,
            },
          },
          {
            name: "isActive",
            label: "Aktywne konto",
            type: InputType.CHECKBOX,
            value: initValues.isActive.toString(),
          },
          {
            name: "isStaff",
            label: "Ma dostęp do paneli admina",
            type: InputType.CHECKBOX,
            value: initValues.isStaff.toString(),
          },
        ]}
      />
    </div>
  );
};

export default UserForm;
