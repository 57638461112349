import React, { useState, useEffect } from "react";
import axiosService from "../utils/axios";
import PaginationTableComponent from "../components/PaginationTableComponent";
import LinkComponent from "../components/LinkComponent";
import ButtonComponent from "../components/ButtonComponent";
import ModalDeleteComponent from "../components/ModalDeleteComponent";
import { PaginationTableData } from "../types/types";
import { API } from "../const/const";

const ITEM_ON_PAGE: number = 25;

const TagList: React.FC = () => {
  const [tags, setTags] = useState<PaginationTableData>({ data: [], count: 0 });
  const [currentPage, setCurrentPage] = useState<number>(1);

  const getTags = (page: number) => {
    axiosService
      .get(`${API}/tags/?page=${page}&page_size=${ITEM_ON_PAGE}`)
      .then((response) => {
        setTags({
          data: response.data.results,
          count: response.data.count,
        });
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getTags(currentPage);
  }, [currentPage]);

  const handleDelete = (id: number) => {
    axiosService
      .delete(`${API}/tags/${id}/`)
      .then(() => {
        setCurrentPage(1);
        getTags(1);
      })
      .catch((error) => console.log(error));
  };

  const handleSync = () => {
    axiosService
      .post(`${API}/tags_sync/`)
      .then(() => getTags(currentPage))
      .catch((error) => console.log(error));
  };

  return (
    <div>
      <ButtonComponent text="Synchronizacja" callback={handleSync} />
      <PaginationTableComponent
        search="name"
        data={tags}
        itemOnPage={ITEM_ON_PAGE}
        currentPage={currentPage}
        onPageChange={(num) => setCurrentPage(num)}
        columns={[
          {
            key: "tag",
            name: "Tag",
            className: "w-2/3",
          },
          {
            key: "created_at",
            name: "Created at",
            className: "w-1/3",
          },
          {
            key: "buttons",
            name: "",
            onRender: (id: number) => (
              <div className="flex justify-end">
                <LinkComponent text="Edycja" url={`/tag/${id}`} />
                <ModalDeleteComponent
                  name={`Tag: ${tags.data.find((tag) => tag.id === id)?.tag}`}
                  buttonComponent={<LinkComponent text="Usuń" />}
                  onDelete={() => handleDelete(id)}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default TagList;
