import React, { useState, useEffect, useCallback } from "react";
import axiosService from "../utils/axios";
import { API } from "../const/const";
import { PaginationTableData } from "../types/types";
import PaginationTableComponent from "../components/PaginationTableComponent";
import LinkComponent from "../components/LinkComponent";
import { useMessage } from "../utils/message";
import ModalDeleteComponent from "../components/ModalDeleteComponent";

const ITEM_ON_PAGE: number = 10;

const CampaignList: React.FC = () => {
  const [campaignData, setCampaignData] = useState<PaginationTableData>({
    data: [],
    count: 0,
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { infoMessage, errorMessage } = useMessage();

  const getCampaigns = useCallback(
    (page: number) => {
      axiosService
        .get(`${API}/campaigns/?page=${page}&page_size=${ITEM_ON_PAGE}`)
        .then((response) => {
          setCampaignData({
            data: response.data.results,
            count: response.data.count,
          });
        })
        .catch((error) =>
          errorMessage(
            `An error occurred while retrieving campaign list: ${error}`
          )
        );
    },
    [errorMessage]
  );

  useEffect(() => {
    getCampaigns(currentPage);
  }, [getCampaigns, currentPage]);

  const handleDelete = (id: number) => {
    infoMessage("Campaign deletion started");
    axiosService
      .delete(`${API}/campaigns/${id}/`)
      .then(() => {
        setCurrentPage(1);
        getCampaigns(1);
      })
      .catch((error) =>
        errorMessage(`An error occurred while deleting the campaign.: ${error}`)
      );
  };

  return (
    <div>
      <PaginationTableComponent
        search="name"
        data={campaignData}
        itemOnPage={ITEM_ON_PAGE}
        currentPage={currentPage}
        onPageChange={(num) => setCurrentPage(num)}
        columns={[
          {
            key: "name",
            name: "Nazwa",
            className: "w-2/3",
            max_length: 100,
          },
          {
            key: "status",
            name: "status",
          },
          {
            key: "external_service",
            name: "Usługa",
          },
          {
            key: "created_at",
            name: "created At",
            className: "w-1/3",
          },
          {
            key: "buttons",
            name: "",
            onRender: (id: number) => (
              <div className="flex justify-end">
                <LinkComponent text="Edycja" url={`/campaign/${id}`} />
                <ModalDeleteComponent
                  name={`Kampania: ${
                    campaignData.data.find((campaign) => campaign.id === id)
                      ?.name
                  }`}
                  buttonComponent={<LinkComponent text="Usuń" />}
                  onDelete={() => handleDelete(id)}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default CampaignList;
