import React, { useState, useEffect, useCallback } from "react";
import axiosService from "../utils/axios";
import { useParams, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import {
  InputType,
  StatusEnum,
  StatusOptionWithId,
  EnumWithId,
  FindElementWithId,
} from "../const/Status";
import { API } from "../const/const";
import {
  Scenario,
  Campaign,
  Phone,
  CandidateStats,
  TScenario,
  ExternalService,
} from "../types/types";
import TableComponent from "../components/TableComponent";
import LinkComponent from "../components/LinkComponent";
import FormComponent from "../components/FormComponent";
import ButtonComponent from "../components/ButtonComponent";
import { getTime, timeToUtc } from "../const/Time";
import { toBool } from "../const/Boolean";
import { useMessage } from "../utils/message";
import CandidateStatsComponent from "../components/CandidateStatsComponent";
import ModalDeleteComponent from "../components/ModalDeleteComponent";
import DropDownComponent from "../components/DropDownComponent";

const DEFAULT_CAMPAIGN_HOURS = {
  start: timeToUtc({ hour: "0", minute: "0" }),
  end: timeToUtc({ hour: "23", minute: "55" }),
};

const CampaignForm: React.FC = () => {
  const [campaignData, setCampaignData] = useState<Campaign>({
    id: -1,
    name: "",
    status: StatusEnum.INACTIVE,
    start_time: DEFAULT_CAMPAIGN_HOURS.start,
    end_time: DEFAULT_CAMPAIGN_HOURS.end,
    call_on_working_days: true,
    created_at: "",
    phones: [],
    external_service: ExternalService.NONE,
  });
  const [scenarios, setScenarios] = useState<Scenario[]>([]);
  const [templateScenarios, setTemplateScenarios] = useState<TScenario[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<number>(-1);
  const [phones, setPhones] = useState<Phone[]>([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const { successMessage, infoMessage, errorMessage } = useMessage();
  const [candidateStats, setCandidateStats] = useState<CandidateStats>();

  useEffect(() => {
    axiosService
      .get(`${API}/phones`)
      .then((res) => {
        setPhones(res.data);
      })
      .catch((error) =>
        errorMessage(`An error occurred while receiving phone list: ${error}`)
      );

    axiosService
      .get(`${API}/template_scenarios`)
      .then((res) => {
        setTemplateScenarios(res.data);
      })
      .catch((error) =>
        errorMessage(
          `An error occurred while receiving template scenario list: ${error}`
        )
      );
  }, [errorMessage]);

  const getScenarios = useCallback(() => {
    axiosService
      .get(`${API}/get_campaign_scenarios/${id}/`)
      .then((res) => {
        setScenarios(res.data);
      })
      .catch((error) =>
        errorMessage(
          `An error occurred while retrieving campaign scenarios: ${error}`
        )
      );
  }, [id, errorMessage]);

  useEffect(() => {
    if (id) {
      axiosService
        .get(`${API}/campaigns/${id}/`)
        .then((res) => {
          setCampaignData(res.data);
        })
        .catch((error) =>
          errorMessage(
            `An error occurred while retrieving the campaign list: ${error}`
          )
        );
      getScenarios();
      axiosService
        .get(`${API}/candidates_stats/${id}/`)
        .then((res) => {
          setCandidateStats(res.data);
        })
        .catch((error) =>
          errorMessage(
            `An error occurred while retrieving candidate stats: ${error}`
          )
        );
    }
  }, [id, errorMessage, getScenarios]);

  const handleSubmit = (formData: any) => {
    const _campaignData = {
      name: formData.CampaignName,
      status: formData.CampaignStatus.name,
      start_time: timeToUtc(formData.CampaignStartTime),
      end_time: timeToUtc(formData.CampaignEndTime),
      call_on_working_days: toBool(formData.CampaignWorkingDay),
      phones: formData.CampaignPhones,
      external_service: formData.CampaignExternalService.name,
    };
    if (_campaignData.name.trim() === "") {
      errorMessage("Campaign field cannot be empty");
      return;
    }
    infoMessage("Campaign data recording has started");
    if (id) {
      axiosService
        .put(`${API}/campaigns/${id}/`, _campaignData)
        .then(() => {
          navigate("/campaigns");
          successMessage("Campaign data recorded successfully");
        })
        .catch((error) =>
          errorMessage(`An error occurred while editing the campaign: ${error}`)
        );
    } else {
      axiosService
        .post(`${API}/campaigns/`, _campaignData)
        .then(() => {
          navigate("/campaigns");
          successMessage("Campaign created successfully");
        })
        .catch((error) =>
          errorMessage(
            `An error occurred while creating the campaign: ${error}`
          )
        );
    }
  };

  const handleRemoveScenario = (id: number) => {
    axiosService
      .delete(`${API}/scenarios/${id}/`)
      .then((response) => {
        setScenarios(scenarios.filter((scenario) => scenario.id !== id));
        successMessage("Scenario removal was successful");
      })
      .catch((error) =>
        errorMessage(`An error occurred while deleting the campaign: ${error}`)
      );
  };

  const addScenarioByTemplate = () => {
    axiosService
      .post(`${API}/add_scenario_by_template/${id}/${selectedTemplate}/`)
      .then(() => {
        successMessage("Scenario was added");
        getScenarios();
      })
      .catch((error) =>
        errorMessage(`An error occurred while add the scenario: ${error}`)
      );
  };

  const handleSync = () => {
    infoMessage("Started receiving data");
    axiosService({
      method: "GET",
      url: `${API}/result_sync/${id}`,
      responseType: "blob",
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: "text/csv" });
        saveAs(blob, "candidates.csv");
        successMessage("Data received to file successfully");
      })
      .catch((error) => errorMessage(`Data received with error: ${error}`));
  };

  return (
    <div className="bg-gray-100 p-6">
      <FormComponent
        onSave={handleSubmit}
        buttonName={id ? "Edytuj" : "Dodaj"}
        fields={[
          {
            name: "CampaignName",
            label: "Nazwa kampanii",
            placeholder: "tu wpisz nazwę kampanii",
            require: true,
            type: InputType.TEXT,
            value: id ? campaignData.name : "",
          },
          {
            name: "CampaignStatus",
            label: "Status",
            type: InputType.DROPDOWN,
            options: StatusOptionWithId,
            value: id
              ? StatusOptionWithId.find((s) => s.name === campaignData.status)
              : StatusOptionWithId.find((s) => s.name === StatusEnum.INACTIVE),
          },
          {
            name: "CampaignStartTime",
            label: "Czas rozpoczęcia",
            type: InputType.TIME,
            value: getTime(campaignData.start_time),
          },
          {
            name: "CampaignEndTime",
            label: "Czas zakończenia",
            type: InputType.TIME,
            value: getTime(campaignData.end_time),
          },
          {
            name: "CampaignWorkingDay",
            label: "Nie dzwonić w niedzielę",
            type: InputType.CHECKBOX,
            value: campaignData.call_on_working_days.toString(),
          },
          {
            name: "CampaignPhones",
            label: "Telefony dzwoniące",
            type: InputType.TAG,
            options: phones.map(({ id, name }) => ({ id, name })),
            value: campaignData.phones ? campaignData.phones : [],
          },
          {
            name: "CampaignExternalService",
            label: "Usługa synchronizacji",
            type: InputType.DROPDOWN,
            options: EnumWithId(ExternalService),
            value: id
              ? FindElementWithId(
                  ExternalService,
                  campaignData.external_service
                )
              : FindElementWithId(ExternalService, ExternalService.NONE),
          },
        ]}
      />
      <div className="pt-6">
        <CandidateStatsComponent campaignId={id} data={candidateStats} />
      </div>
      <div className="mt-12">
        {scenarios.length > 0 && (
          <TableComponent
            search="name"
            data={scenarios}
            columns={[
              {
                key: "name",
                name: "Nazwa",
                className: "w-2/3",
                max_length: 100,
              },
              {
                key: "status",
                name: "status",
                className: "w-1/3",
              },
              {
                key: "buttons",
                name: "",
                onRender: (scenarioId: number) => (
                  <div className="flex justify-end">
                    <LinkComponent
                      text="Edycja"
                      url={`/scenario/${scenarioId}/${id}`}
                    />
                    <ModalDeleteComponent
                      name={`Scenariusz: ${
                        scenarios.find((scenario) => scenario.id === scenarioId)
                          ?.name
                      }`}
                      buttonComponent={<LinkComponent text="Usuń" />}
                      onDelete={() => handleRemoveScenario(scenarioId)}
                    />
                    <LinkComponent
                      text="Zaawansowany"
                      url={`/scenario_creator/${scenarioId}/${id}`}
                    />
                  </div>
                ),
              },
            ]}
          />
        )}
      </div>
      {id && (
        <div>
          <div className="py-6">
            <ButtonComponent
              callback={() => navigate(`/scenario/new/${id}`)}
              text="Dodaj scenariusz"
            />
          </div>
          <div className="py-6">
            <ButtonComponent
              callback={handleSync}
              text="Pobierz rezultaty kampanii"
            />
          </div>
        </div>
      )}
      {templateScenarios && (
        <div>
          <DropDownComponent
            label="Wybier szablon"
            options={templateScenarios.map((tScenario) => ({
              id: tScenario.id,
              name: tScenario.name,
            }))}
            callback={(option) => {
              setSelectedTemplate(option.id);
            }}
          />
          {selectedTemplate !== -1 && (
            <div className="py-6">
              <ButtonComponent
                callback={() => addScenarioByTemplate()}
                text="Dodaj scenariusz według szablonu"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CampaignForm;
