import moment from "moment";
import { StatusEnum } from "../const/Status";
import { Phone } from "../types/types";
import { Link } from "react-router-dom";
import ButtonComponent from "./ButtonComponent";
import ModalDeleteComponent from "./ModalDeleteComponent";

interface IPhoneDetailsComponent {
  data: Phone;
  onDelete(): void;
  editLink: string;
}

const PhoneDetailsComponent = (props: IPhoneDetailsComponent) => (
  <div className="min-w-[420px]">
    <div className="bg-gray-200 p-3 rounded-lg">
      <div className="flex justify-between">
        <h1 className="font-bold text-xl">{props.data.phone}</h1>
        <h1
          className={`font-bold ${
            props.data.status === StatusEnum.ACTIVE
              ? "text-green-500"
              : "text-red-600"
          }`}
        >
          {props.data.status}
        </h1>
      </div>
      <div className="flex">
        <h1 className="pr-2 font-bold">Nazwa:</h1>
        <h1>{props.data.name}</h1>
      </div>
      <div className="flex">
        <h1 className="pr-2 font-bold">Status dzwonienia:</h1>
        <h1>{props.data.call_status}</h1>
      </div>
      <div className="flex">
        <h1 className="pr-2 font-bold">Aktualizacja:</h1>
        <h1>{moment(props.data.last_updated).format("HH:mm:ss DD-MM-YYYY")}</h1>
      </div>
      <div className="flex pt-3 justify-between">
        <Link className="pr-3" to={props.editLink}>
          <ButtonComponent className="h-8" text="Edycja" />
        </Link>
        <ModalDeleteComponent
          name={`Telefon: ${props.data.name}`}
          buttonComponent={<ButtonComponent className="h-8" text="Usuń" />}
          onDelete={() => props.onDelete()}
        />
      </div>
    </div>
  </div>
);

export default PhoneDetailsComponent;
