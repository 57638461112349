import React from "react";
import { Pagination } from "flowbite-react";
import TableComponent from "../components/TableComponent";
import { Column, PaginationTableData } from "../types/types";

type PaginationTable = {
  data: PaginationTableData;
  columns: Column[];
  search?: string;
  currentPage: number;
  itemOnPage: number;
  onPageChange(page: number): void;
};

const PaginationTableComponent = ({
  data,
  columns,
  search,
  currentPage,
  itemOnPage,
  onPageChange,
}: PaginationTable) => {
  const totalPages = Math.floor((data.count - 1) / itemOnPage) + 1;
  return (
    <>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
      <TableComponent data={data.data} columns={columns} search={search} />
    </>
  );
};

export default PaginationTableComponent;
