import React, { useState, useEffect } from "react";
import axiosService from "../utils/axios";
import { API } from "../const/const";
import { PaginationData } from "../types/types";
import { useMessage } from "../utils/message";
import PaginationFilterTableComponent, {
  PaginationColumn,
} from "../components/PaginationFilterTableComponent";

type Header = {
  id: number;
  name: string;
  phone: string;
};

const header: PaginationColumn<Header>[] = [
  { name: "Id", key: "id", withSearching: true, withSorting: true },
  { name: "Nazwa", key: "name", withSearching: true, withSorting: true },
  { name: "Telefon", key: "phone", withSearching: true, withSorting: true },
];

const ITEM_ON_PAGE = 10;

const UnsignedCandidateList: React.FC = () => {
  const { successMessage, errorMessage } = useMessage();
  const [data, setData] = useState<PaginationData<Header>>({
    data: [],
    count: 0,
  });
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    axiosService
      .get(
        `${API}/get_unsigned_candidates/?page=${currentPage}&page_size=${ITEM_ON_PAGE}`
      )
      .then((response) => {
        setData({
          data: response.data.results,
          count: response.data.count,
        });
      })
      .catch((error) =>
        errorMessage(
          `An error occurred while retrieving unsigned candidates: ${error}`
        )
      );
  }, [errorMessage, successMessage, currentPage]);

  return (
    <div>
      <h1 className="font-bold text-3xl pb-3">Lista kandydatów bez kampanii</h1>
      <PaginationFilterTableComponent
        header={header}
        data={data}
        currentPage={currentPage}
        itemOnPage={ITEM_ON_PAGE}
        onPageChange={(num) => setCurrentPage(num)}
      />
    </div>
  );
};

export default UnsignedCandidateList;
