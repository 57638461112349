import React, { useState, useEffect } from "react";
import { saveAs } from "file-saver";
import axiosService from "../utils/axios";
import PaginationFilterTableComponent, {
  PaginationColumn,
  PaginationColumnType,
} from "../components/PaginationFilterTableComponent";
import { API } from "../const/const";
import ButtonComponent from "../components/ButtonComponent";
import { useMessage } from "../utils/message";
import { PaginationData } from "../types/types";

type Header = {
  id: number;
  campaign: string;
  phone: string;
  scenario: string;
  question: string;
  answer: string;
  tags: string[];
  direction: string;
  is_sync: boolean;
};

const header: PaginationColumn<Header>[] = [
  { name: "Campaign", key: "campaign", withSearching: true, withSorting: true },
  { name: "Phone", key: "phone", withSearching: true, withSorting: true },
  { name: "Scenario", key: "scenario", withSearching: true, withSorting: true },
  { name: "Question", key: "question", withSearching: true, withSorting: true },
  { name: "Answer", key: "answer", withSearching: true, withSorting: true },
  {
    name: "Tags",
    key: "tags",
    withSearching: true,
    withSorting: true,
    type: PaginationColumnType.BADGE,
  },
  {
    name: "Call direction",
    key: "direction",
    withSearching: true,
    withSorting: true,
  },
  { name: "Is sync", key: "is_sync", withSearching: true, withSorting: true },
];

const ITEM_ON_PAGE: number = 25;

const Result: React.FC = () => {
  const [data, setData] = useState<PaginationData<Header>>({
    data: [],
    count: 0,
  });
  const { successMessage, infoMessage, errorMessage } = useMessage();
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    axiosService
      .get(`${API}/results/?page=${currentPage}&page_size=${ITEM_ON_PAGE}`)
      .then((response) => {
        setData({
          data: response.data.results,
          count: response.data.count,
        });
      })
      .catch((error) => errorMessage(`Get results with error: ${error}`));
  }, [currentPage, errorMessage]);

  const handleSync = () => {
    infoMessage("Started receiving data");
    axiosService({
      method: "GET",
      url: `${API}/result_sync/`,
      responseType: "blob",
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: "text/csv" });
        saveAs(blob, "candidates.csv");
        successMessage("Data received to file successfully");
      })
      .catch((error) => errorMessage(`Data received with error: ${error}`));
  };

  return (
    <div>
      <ButtonComponent text={"Pobierz wyniki do CSV"} callback={handleSync} />
      <PaginationFilterTableComponent
        header={header}
        data={data}
        currentPage={currentPage}
        itemOnPage={ITEM_ON_PAGE}
        onPageChange={(num) => setCurrentPage(num)}
      />
    </div>
  );
};

export default Result;
