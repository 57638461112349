import React, { useState, useEffect } from "react";
import axiosService from "../utils/axios";
import { useParams, useNavigate } from "react-router-dom";
import { EnumWithId, FindElementWithId, InputType } from "../const/Status";
import { API } from "../const/const";
import { Candidate, CandidateStatus, DialogStatus } from "../types/types";
import FormComponent from "../components/FormComponent";

const CandidateForm: React.FC = () => {
  const [candidate, setCandidate] = useState<Candidate>();
  const { id, candidate_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (candidate_id) {
      axiosService
        .get(`${API}/candidates/${candidate_id}/`)
        .then((res) => {
          setCandidate(res.data);
        })
        .catch((error) => console.log(error));
    }
  }, [candidate_id]);

  const handleSubmit = (formData: any) => {
    const candidateData = {
      name: formData.name,
      phone: formData.phone,
      status: formData.status.name,
      dialog_status: formData.dialog_status.name,
      campaign: id,
      sid: formData.sid,
      info: formData.info,
    };
    if (candidate_id) {
      axiosService
        .put(`${API}/candidates/${candidate_id}/`, candidateData)
        .then(() => {
          navigate(`/campaign/${id}`);
        })
        .catch((error) => console.log(error));
    } else {
      axiosService
        .post(`${API}/candidates/`, candidateData)
        .then(() => {
          navigate(`/campaign/${id}/candidates`);
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <div className="bg-gray-100 p-6">
      <FormComponent
        onSave={handleSubmit}
        buttonName="Zapisz"
        fields={[
          {
            name: "name",
            label: "Imię kandydata",
            placeholder: "tu wpisz imię kandydata",
            require: true,
            type: InputType.TEXT,
            value: candidate_id && candidate ? candidate.name : "",
          },
          {
            name: "phone",
            label: "Telefon kandydata",
            placeholder: "tu wpisz numer telefona kandydata",
            require: true,
            type: InputType.TEXT,
            value: candidate_id && candidate ? candidate.phone : "",
          },
          {
            name: "status",
            label: "Status",
            type: InputType.DROPDOWN,
            options: EnumWithId(CandidateStatus),
            value: FindElementWithId(
              CandidateStatus,
              candidate_id && candidate
                ? candidate?.status
                : CandidateStatus.PENDING
            ),
          },
          {
            name: "dialog_status",
            label: "Dialog status",
            type: InputType.DROPDOWN,
            options: EnumWithId(DialogStatus),
            value: FindElementWithId(
              DialogStatus,
              candidate_id && candidate
                ? candidate?.dialog_status
                : DialogStatus.PENDING
            ),
          },
          {
            name: "sid",
            label: "Sid",
            placeholder: "tu wpisz sid kandydata",
            require: false,
            type: InputType.TEXT,
            value: candidate_id && candidate ? candidate.sid : "",
          },
          {
            name: "info",
            label: "Info",
            placeholder: "tu wpisz info kandydata",
            require: false,
            type: InputType.TEXT,
            value: candidate_id && candidate ? candidate.info : "",
          },
        ]}
      />
    </div>
  );
};

export default CandidateForm;
