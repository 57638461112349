import React, { useState, useEffect } from "react";
import axiosService from "../utils/axios";
import { API } from "../const/const";
import { PaginationTableData } from "../types/types";
import PaginationTableComponent from "../components/PaginationTableComponent";
import LinkComponent from "../components/LinkComponent";
import ModalDeleteComponent from "../components/ModalDeleteComponent";

const ITEM_ON_PAGE: number = 10;

const InvalidActionList: React.FC = () => {
  const [invalidData, setInvalidData] = useState<PaginationTableData>({
    data: [],
    count: 0,
  });
  const [currentPage, setCurrentPage] = useState<number>(1);

  const getActions = (page: number) => {
    axiosService
      .get(`${API}/invalid_actions/?page=${page}&page_size=${ITEM_ON_PAGE}`)
      .then((response) => {
        setInvalidData({
          data: response.data.results,
          count: response.data.count,
        });
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getActions(currentPage);
  }, [currentPage]);

  const handleDelete = (id: number) => {
    axiosService
      .delete(`${API}/invalid_actions/${id}/`)
      .then(() => {
        setCurrentPage(1);
        getActions(1);
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      <PaginationTableComponent
        search="name"
        data={invalidData}
        itemOnPage={ITEM_ON_PAGE}
        currentPage={currentPage}
        onPageChange={(num) => setCurrentPage(num)}
        columns={[
          {
            key: "content",
            name: "Nazwa",
            className: "w-2/3",
          },
          {
            key: "lang",
            name: "język",
          },
          {
            key: "created_at",
            name: "created At",
            className: "w-1/3",
          },
          {
            key: "buttons",
            name: "",
            onRender: (id: number) => (
              <div className="flex justify-end">
                <LinkComponent text="Edycja" url={`/invalid_action/${id}`} />
                <ModalDeleteComponent
                  name={`Zła akcja: ${
                    invalidData.data.find((invalid) => invalid.id === id)
                      ?.content
                  }`}
                  buttonComponent={<LinkComponent text="Usuń" />}
                  onDelete={() => handleDelete(id)}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default InvalidActionList;
