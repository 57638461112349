import moment from "moment";
import { TimeType } from "../types/types";

export const getHoursInDay = () => {
  let hourEnum: { [key: string]: string } = {};
  Array.from(
    { length: 24 },
    (_, index) => (hourEnum[index.toString()] = index.toString())
  );
  return hourEnum;
};

export const HourList = () => {
  const HoursEnum = getHoursInDay();
  return (Object.keys(HoursEnum) as Array<keyof typeof HoursEnum>).map(
    (key, index) => ({
      id: index,
      name: HoursEnum[key],
    })
  );
};

export const getMinutesInHour = () => {
  let minuteEnum: { [key: string]: string } = {};
  Array.from(
    { length: 12 },
    (_, index) => (minuteEnum[(index * 5).toString()] = (index * 5).toString())
  );
  return minuteEnum;
};

export const MinutesList = () => {
  const MinutesEnum = getMinutesInHour();
  return (Object.keys(MinutesEnum) as Array<keyof typeof MinutesEnum>).map(
    (key, index) => ({
      id: index,
      name: MinutesEnum[key],
    })
  );
};

export const getTime = (time: string): TimeType => {
  const hour = moment(time).utc().hour().toString();
  const minute = moment(time).utc().minute().toString();
  return { hour, minute };
};

export const timeToUtc = (time: TimeType): string =>
  moment
    .utc()
    .hour(Number(time.hour))
    .minute(Number(time.minute))
    .second(0)
    .format("YYYY-MM-DDTHH:mm:ss[Z]");
