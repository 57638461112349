import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Message {
  id: number;
  message: string;
  type: string;
}

interface MessagesState {
  messages: Message[];
}

const initialState: MessagesState = {
  messages: [],
};

const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    addMessage: (state, action: PayloadAction<Message>) => {
      state.messages.push(action.payload);
    },
    removeMessageById: (state, action: PayloadAction<number>) => {
      state.messages = state.messages.filter(
        (msg) => msg.id !== action.payload
      );
    },
  },
});

export const { addMessage, removeMessageById } = messagesSlice.actions;

export default messagesSlice.reducer;
