import React, { useState, useEffect, useRef } from "react";
import { IoSearch } from "react-icons/io5";
import { FiChevronDown } from "react-icons/fi";

interface IComponent {
  label: string;
  options: { id: number; name: string }[];
  callback?(option: { id: number; name: string }): void;
  className?: string;
}

const DropDownComponent = (props: IComponent) => {
  const { label, options, callback, className } = props;
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedVal, setSelectedVal] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
    setSelectedVal(null);
  };

  const handleOptionClick = (option: { id: number; name: string }) => {
    setSelectedVal(option);
    setIsOpen(false);
    if (callback) callback(option);
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleDocumentClick = (e: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(e.target as Node)
    )
      setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen && inputRef.current) inputRef.current.focus();
    if (isOpen) setQuery("");
  }, [isOpen]);

  const filterOptions = () =>
    options.filter((op) => op.name.toLowerCase().includes(query.toLowerCase()));

  return (
    <div className="relative" ref={containerRef}>
      <button
        id="dropdownSearchButton"
        data-dropdown-toggle="dropdownSearch"
        data-dropdown-placement="bottom"
        className={`text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 ${className}`}
        type="button"
        onClick={toggleDropdown}
      >
        {selectedVal ? selectedVal.name : label}
        <FiChevronDown
          className={`w-4 h-4 ms-3 ${isOpen ? "transform rotate-180" : ""}`}
        />
      </button>

      {isOpen && (
        <div
          id="dropdownSearch"
          className="z-10 bg-white rounded-lg shadow w-60 dark:bg-gray-700 absolute"
        >
          <div className="p-3">
            <label htmlFor="input-group-search" className="sr-only">
              Search
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                <IoSearch className="w-4 h-4 text-gray-500 dark:text-gray-400" />
              </div>
              <input
                type="text"
                id="input-group-search"
                className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search user"
                value={query}
                onChange={handleFilterChange}
                ref={inputRef}
              />
            </div>
          </div>
          <ul
            className="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownSearchButton"
          >
            {filterOptions().map((option) => (
              <li key={`checkbox-item-${option.id}`}>
                <div
                  className={`flex items-center ps-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600 ${
                    selectedVal?.id === option.id ? "bg-blue-100" : ""
                  }`}
                  onClick={() => handleOptionClick(option)}
                >
                  <label
                    htmlFor={`checkbox-item-${option.id}`}
                    className="w-full py-2 ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                  >
                    {option.name}
                  </label>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropDownComponent;
