import { Badge, Checkbox, FileInput, Label } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { MdCancel } from "react-icons/md";
import ButtonComponent from "./ButtonComponent";
import DropDownComponent from "./DropDownComponent";
import TextInputComponent from "./TextInputComponent";
import { InputType } from "../const/Status";
import { HourList, MinutesList } from "../const/Time";
import { toBool } from "../const/Boolean";
import { TimeType } from "../types/types";

interface OptionType {
  id: number;
  name: string;
}

export interface InvalidActionType {
  id: number;
  name: string;
  lang: string;
}

export interface AudioType {
  name: string;
  file: string | File | null;
}

type ValueType =
  | string
  | OptionType
  | number[]
  | InvalidActionType[]
  | AudioType
  | TimeType;

interface InputFields {
  name: string;
  label?: string;
  placeholder?: string;
  require?: boolean;
  type: string;
  options?: OptionType[] | InvalidActionType[];
  value?: ValueType;
}

interface IFormComponent {
  fields: InputFields[];
  onSave: (values: Record<string, any>) => void;
  buttonName: string;
}

const renderField = (
  field: InputFields,
  data: Record<string, any>,
  setData: React.Dispatch<React.SetStateAction<Record<string, ValueType>>>
) => {
  switch (field.type) {
    case InputType.DROPDOWN:
      return (
        <div className="mb-8">
          <DropDownComponent
            label={data[field.name]?.name ?? ""}
            options={field.options ? field.options : []}
            callback={(option) => {
              setData((prev) => {
                prev[field.name] = option;
                return { ...prev };
              });
            }}
          />
        </div>
      );
    case InputType.FILE:
      return (
        <div>
          {
            // TODO: add file input, pictureinput etc.
          }
        </div>
      );
    case InputType.AUDIO:
      const srcFile = data[field.name]?.file;
      return (
        <div>
          {srcFile && !(srcFile instanceof File) ? (
            <div>
              <div className="flex items-center">
                <audio key={srcFile} controls>
                  <source src={srcFile} type="audio/mpeg" />
                  Your browser does not support the audio tag.
                </audio>
                <ButtonComponent
                  text="Edytuj"
                  callback={() => {
                    setData((prev) => {
                      prev[field.name] = {
                        name: "",
                        file: null,
                      };
                      return { ...prev };
                    });
                  }}
                />
              </div>
            </div>
          ) : (
            <div>
              <TextInputComponent
                classname="mb-6"
                name={field.name}
                id={field.name}
                type="text"
                placeholder={field.placeholder}
                require={field.require}
                onChange={(e) => {
                  setData((prev) => {
                    prev[field.name] = {
                      name: e.target.value,
                      file: data[field.name]?.file,
                    };
                    return { ...prev };
                  });
                }}
              />
              <FileInput
                onChange={(e) => {
                  setData((prev) => {
                    prev[field.name] = {
                      name: data[field.name]?.name,
                      file:
                        e.target.files && e.target.files.length > 0
                          ? e.target.files[0]
                          : null,
                    };
                    return { ...prev };
                  });
                }}
              />
            </div>
          )}
        </div>
      );
    case InputType.TIME:
      return (
        <div className="mb-8 flex">
          <DropDownComponent
            label={data[field.name]?.hour ?? "Hour"}
            options={HourList()}
            callback={(selectedHour) => {
              setData((prev) => {
                prev[field.name] = {
                  hour: selectedHour.name,
                  minute: data[field.name].minute,
                };
                return { ...prev };
              });
            }}
          />
          <div className="pl-4">
            <DropDownComponent
              label={data[field.name]?.minute ?? "Minute"}
              options={MinutesList()}
              callback={(selectedMinute) => {
                setData((prev) => {
                  prev[field.name] = {
                    hour: data[field.name].hour,
                    minute: selectedMinute.name,
                  };
                  return { ...prev };
                });
              }}
            />
          </div>
        </div>
      );
    case InputType.CHECKBOX:
      return (
        <div>
          <Checkbox
            id={field.name}
            checked={toBool(data[field.name])}
            onChange={(e) => {
              setData((prev) => {
                prev[field.name] = e.target.checked.toString();
                return { ...prev };
              });
            }}
          />
        </div>
      );
    case InputType.INVALID_ACTIONS:
      const options = (
        field.options ? field.options : []
      ) as InvalidActionType[];
      return (
        <div className="flex-col">
          {data[field.name] &&
            data[field.name].map((action: InvalidActionType, index: number) => (
              <div className="flex" key={`${action.id}_${index}`}>
                <Label htmlFor={action.name} className="m-2">
                  {action.lang}
                </Label>
                <DropDownComponent
                  label={action.name}
                  options={options}
                  callback={(option) => {
                    setData((prev) => {
                      const prevData: InvalidActionType[] = prev[
                        field.name
                      ] as InvalidActionType[];
                      prevData[index] = options.find(
                        (o) => o.id === option.id
                      )!;
                      prev[field.name] = prevData;
                      return { ...prev };
                    });
                  }}
                  className="m-2"
                />
                <ButtonComponent
                  callback={() => {
                    setData((prev) => {
                      const prevData: InvalidActionType[] = prev[
                        field.name
                      ] as InvalidActionType[];
                      prevData.splice(index, 1);
                      prev[field.name] = prevData;
                      return { ...prev };
                    });
                  }}
                  text="Usuń"
                  type="button"
                  className="m-2"
                />
              </div>
            ))}
          <ButtonComponent
            callback={() => {
              setData((prev) => {
                const nextPrev = { ...prev };
                const prevData: InvalidActionType[] = [
                  ...(nextPrev[field.name] as InvalidActionType[]),
                ];
                prevData.push(options[0]);
                nextPrev[field.name] = prevData;
                return nextPrev;
              });
            }}
            text="Dodaj"
            disabled={options.length === 0}
            type="button"
            className="m-2"
          />
        </div>
      );
    case InputType.NUMBER:
    case InputType.EMAIL:
    case InputType.PASSWORD:
    case InputType.TEXT:
      return (
        <TextInputComponent
          classname="mb-6"
          name={field.name}
          id={field.name}
          type={field.type}
          placeholder={field.placeholder}
          require={field.require}
          onChange={(e) => {
            setData((prev) => {
              prev[field.name] = e.target.value;
              return { ...prev };
            });
          }}
          value={data[field.name] || ""}
        />
      );
    case InputType.TAG:
      if (!data[field.name] || !field.options || field.options?.length === 0)
        return <div />;
      const convertTags = data[field.name].map((v: number) =>
        field.options?.find((tag: OptionType) => tag.id === v)
      );

      return (
        <div>
          <div className="flex flex-wrap gap-2">
            {convertTags.map((tag: OptionType) => (
              <Badge
                color="info"
                key={tag.id}
                icon={MdCancel}
                onClick={(e) => {
                  setData((prev) => {
                    const list = [...(prev[field.name] as number[])];
                    const index = list.indexOf(tag.id);
                    if (index !== -1) {
                      list.splice(index, 1);
                    }
                    prev[field.name] = list;
                    return { ...prev };
                  });
                }}
              >
                {tag.name}
              </Badge>
            ))}
          </div>
          <DropDownComponent
            label="wybierz"
            options={field.options}
            callback={(option) => {
              setData((prev) => {
                const list = [...(prev[field.name] as number[])];
                if (!list.includes(option.id)) list.push(option.id);
                prev[field.name] = list;
                return { ...prev };
              });
            }}
          />
        </div>
      );
    default:
      return <div>UNDEFINED</div>;
  }
};

const FormComponent: React.FC<IFormComponent> = ({
  onSave,
  fields,
  buttonName,
}) => {
  const [data, setData] = useState<Record<string, ValueType>>({});

  useEffect(() => {
    const initialValues: Record<string, ValueType> = {};
    fields.forEach((field) => {
      initialValues[field.name] = field.value ?? "";
    });
    setData(initialValues);
  }, [fields]);

  return (
    <div className="flex max-w-md flex-col gap-4">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSave(data);
        }}
      >
        {fields.map((field) => (
          <div key={field.name}>
            <div className="mb-2 block">
              <Label htmlFor={field.name}>{field.label}</Label>
            </div>
            {renderField(field, data, setData)}
          </div>
        ))}
        <ButtonComponent
          className="mt-12 w-full"
          text={buttonName}
          type="submit"
        />
      </form>
    </div>
  );
};

export default FormComponent;
