import React, { useEffect, useState } from "react";
import axiosService from "../utils/axios";
import { Link, useParams } from "react-router-dom";

import ScenarioInfo from "../components/ScenarionInfo";

import {
  QuestionFull,
  Answer,
  Scenario,
  InvalidAction,
  Tag,
} from "../types/types";
import { API } from "../const/const";
import ButtonComponent from "../components/ButtonComponent";
import FormComponent, { AudioType } from "../components/FormComponent";
import { InputType } from "../const/Status";
import TableComponent from "../components/TableComponent";
import LinkComponent from "../components/LinkComponent";
import { Label } from "flowbite-react";
import { getAudioFileName } from "../utils/utils";
import ModalDeleteComponent from "../components/ModalDeleteComponent";

const ScenarioCreator: React.FC = () => {
  const { campaign, id } = useParams();
  const [scenario, setScenario] = useState<Scenario>();
  const [questions, setQuestions] = useState<QuestionFull[]>([]);
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [invalidActions, setInvalidActions] = useState<InvalidAction[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [questionId, setQuestionId] = useState<number>(-1);
  const [questionContentBool, setQuestionContentBool] =
    useState<boolean>(false);
  const [answerContentBool, setAnswerContentBool] = useState<boolean>(false);

  const [answerId, setAnswerId] = useState<number>(-1);

  const DEFAULT_ELEMENT = "-----";
  const EMPTY_QUESTION = { id: -1, name: DEFAULT_ELEMENT };

  const addQuestion = () => {
    setQuestionContentBool(false);
    const newQuestion = {
      content: "===new Question===",
      lang: "Polish",
      scenario: id,
      next_question: null,
      audio: null,
    };
    axiosService
      .post(`${API}/questions/`, newQuestion)
      .then(() => {
        getData(id);
      })
      .catch((e) => {
        console.log(`New question was created with error: ${e}`);
      });
  };

  const removeQuestion = (questionId: number) => {
    setQuestionContentBool(false);
    axiosService
      .delete(`${API}/questions/${questionId}/`)
      .then(() => {
        console.log("Question was removed");
        getData(id);
      })
      .catch((e) => {
        console.log(`Question was removed with error: ${e}`);
      });
  };

  const addAnswer = (questionId: number) => {
    setAnswerContentBool(false);
    const newAnswer = {
      content: "===new Answer===",
      question: questionId,
      next_question: null,
    };
    axiosService
      .post(`${API}/answers/`, newAnswer)
      .then(() => {
        getData(id);
      })
      .catch((e) => {
        console.log(`New answer was created with error: ${e}`);
      });
  };

  const removeAnswer = (questionId: number, answerId: number) => {
    setAnswerContentBool(false);
    axiosService
      .delete(`${API}/answers/${answerId}/`)
      .then(() => {
        getData(id);
      })
      .catch((e) => {
        console.log(`Answer was removed with error: ${e}`);
      });
  };

  const handleSubmitQuestion = (formData: any, questionId: number) => {
    const audioData = {
      file: formData.AudioContent.file,
      name: formData.AudioContent.file
        ? getAudioFileName(
            formData.AudioContent.name,
            formData.AudioContent.file.name
          )
        : "",
    };

    const editQuestion = {
      content: formData.QuestionContent,
      lang: formData.QuestionLanguage,
      scenario: id,
      next_question:
        formData.QuestionNextQuestion.id !== -1
          ? formData.QuestionNextQuestion.id
          : null,
      audio: audioData,
      template: formData.QuestionTemplate,
    };
    axiosService
      .put(`${API}/edit_question_with_audio/${questionId}/`, editQuestion, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        getData(id);
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
  };

  const saveData = (method: string, url: string, data: any) => {
    axiosService({ method, url, data: data })
      .then(() => getData(id))
      .catch((error) => console.log(error));
  };

  const handleSubmitAnswer = (
    formData: any,
    questionId: number,
    answerId: number
  ) => {
    const editAnswer = {
      content: formData.AnswerContent,
      button: formData.AnswerButton,
      question: questionId,
      next_question:
        formData.AnswerNextQuestion.id !== -1
          ? formData.AnswerNextQuestion.id
          : null,
      tags: formData.AnswerTags,
    };

    const url = `${API}/answers/${answerId}/`;
    const method = "put";

    saveData(method, url, editAnswer);
  };

  const getData = (id: string | undefined) => {
    axiosService
      .get(`${API}/get_scenario_by_id/${id}/`)
      .then((response) => {
        const _scenario: Scenario = response.data.scenario;
        const _questions: QuestionFull[] = response.data.questions;
        const _answers: Answer[] = response.data.answers;
        const _invalidActions: InvalidAction[] = response.data.invalidActions;
        setScenario(_scenario);
        setQuestions(_questions);
        setAnswers(_answers);
        setInvalidActions(_invalidActions);
      })
      .catch((error) => console.log(error));
    axiosService
      .get(`${API}/tags_full/`)
      .then((response) => {
        setTags(response.data);
      })
      .catch((error) => console.log(error));
  };

  const showQuestionDetails = (id: number) => {
    setQuestionContentBool(true);
    setAnswerContentBool(false);
    setQuestionId(id);
  };

  const showAnswerDetails = (id: number) => {
    setAnswerContentBool(true);
    setAnswerId(id);
  };

  useEffect(() => {
    getData(id);
  }, [id]);

  const audioContent = (): AudioType => {
    const selectedQuestion = questions.find((q) => q.id === questionId);
    const name = selectedQuestion?.audio?.audio_name || "";
    const file = selectedQuestion?.audio?.audio_file || null;
    return { name, file };
  };

  return (
    <div>
      <div>
        {scenario && (
          <ScenarioInfo
            info={scenario}
            questions={questions}
            invalidActions={invalidActions}
            onChange={(s) => {
              setScenario(s);
            }}
          />
        )}
      </div>
      <br />
      <div className="flex">
        <div className="pb-12 pr-6">
          <ButtonComponent
            text="Dodaj pytanie"
            callback={() => addQuestion()}
          />
        </div>
        <div className="pb-12">
          <Link to={`/scenario/${id}/${campaign}`}>
            <ButtonComponent text="Edycja scenariusza" />
          </Link>
        </div>
      </div>
      <div className="flex">
        <div className="w-3/12 pr-12 min-w-[500px] ">
          <div className="bg-gray-50 p-5">
            <TableComponent
              search="content"
              data={questions}
              columns={[
                {
                  key: "content",
                  name: "Pytanie",
                  className: "w-2/5",
                },
                {
                  key: "buttons",
                  name: "",
                  onRender: (QuestionId: number) => (
                    <div className="flex justify-end">
                      <ModalDeleteComponent
                        name={`Pytanie: ${
                          questions.find(
                            (question) => question.id === QuestionId
                          )?.content
                        }`}
                        buttonComponent={<LinkComponent text="Usuń" />}
                        onDelete={() => removeQuestion(QuestionId)}
                      />
                      <LinkComponent
                        url={`/scenario_creator/${id}/${campaign}`}
                        text="Edycja"
                        callback={() => showQuestionDetails(QuestionId)}
                      />
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </div>
        {questionContentBool && (
          <div className="w-1/4 p-5 bg-gray-50 min-w-[500px] max-w-[500px]">
            <div className="pb-5">
              <ButtonComponent
                text="Dodaj odpowiedź"
                callback={() => addAnswer(questionId)}
              />
            </div>
            <div className="pb-12">
              <FormComponent
                key={questionId}
                buttonName="Zapisz"
                onSave={(formData) =>
                  handleSubmitQuestion(formData, questionId)
                }
                fields={[
                  {
                    name: "QuestionContent",
                    label: "Pytanie",
                    placeholder: "nazwa pytania",
                    require: true,
                    type: InputType.TEXT,
                    value: questionId
                      ? questions.find((q) => q.id === questionId)?.content
                      : "",
                  },
                  {
                    name: "QuestionTemplate",
                    label: "Szablon",
                    placeholder: "Szablon pytania",
                    require: false,
                    type: InputType.TEXT,
                    value: questionId
                      ? questions.find((q) => q.id === questionId)?.template
                      : "",
                  },
                  {
                    name: "QuestionLanguage",
                    label: "Język",
                    placeholder: "język",
                    require: true,
                    type: InputType.TEXT,
                    value: questionId
                      ? questions.find((q) => q.id === questionId)?.lang
                      : "",
                  },
                  {
                    name: "QuestionNextQuestion",
                    label: "Następne pytanie",
                    placeholder: "język",
                    require: true,
                    type: InputType.DROPDOWN,
                    options: [
                      ...questions
                        .filter(
                          (question) => question.scenario?.toString() === id
                        )
                        .map((question) => ({
                          id: question.id,
                          name: question.content,
                        })),
                      EMPTY_QUESTION,
                    ],

                    value: {
                      id:
                        questions.find(
                          (q) =>
                            q.id ===
                            (questions.find((q) => q.id === questionId)
                              ?.next_question ?? "")
                        )?.id ?? EMPTY_QUESTION.id,
                      name:
                        questions.find(
                          (q) =>
                            q.id ===
                            (questions.find((q) => q.id === questionId)
                              ?.next_question ?? "")
                        )?.content ?? EMPTY_QUESTION.name,
                    },
                  },
                  {
                    name: "AudioContent",
                    label: "Audio",
                    type: InputType.AUDIO,
                    require: false,
                    value: audioContent(),
                  },
                ]}
              />
            </div>

            <TableComponent
              search="content"
              data={answers.filter((a) => a.question === questionId)}
              columns={[
                {
                  key: "content",
                  name: "Odpowiedź",
                  className: "w-2/5",
                },
                {
                  key: "buttons",
                  name: "",
                  onRender: (AnswerId: number) => (
                    <div className="flex justify-end">
                      <ModalDeleteComponent
                        name={`Odpowiedź: ${
                          answers.find((answer) => answer.id === AnswerId)
                            ?.content
                        }`}
                        buttonComponent={<LinkComponent text="Usuń" />}
                        onDelete={() => removeAnswer(questionId, AnswerId)}
                      />
                      <LinkComponent
                        url={`/scenario_creator/${id}/${campaign}`}
                        text="Edycja"
                        callback={() => showAnswerDetails(AnswerId)}
                      />
                    </div>
                  ),
                },
              ]}
            />
          </div>
        )}
        {answerContentBool && (
          <div className="w-1/6 ez pl-12 min-w-[400px] ">
            <div className="bg-gray-50 p-5">
              <div className="pb-6 ">
                <Label className="text-lg">
                  {answerId
                    ? answers.find((a) => a.id === answerId)?.content
                    : ""}
                </Label>
              </div>
              <FormComponent
                key={answerId}
                buttonName="Zapisz"
                onSave={(formData) =>
                  handleSubmitAnswer(formData, questionId, answerId)
                }
                fields={[
                  {
                    name: "AnswerContent",
                    label: "Odpowiedź",
                    placeholder: "nazwa odpowiedzi",
                    require: true,
                    type: InputType.TEXT,
                    value: answerId
                      ? answers.find((a) => a.id === answerId)?.content
                      : "",
                  },
                  {
                    name: "AnswerButton",
                    label: "Przycisk",
                    placeholder: "1",
                    require: true,
                    type: InputType.NUMBER,
                    value: answerId
                      ? answers
                          .find((a) => a.id === answerId)
                          ?.button.toString()
                      : "",
                  },
                  {
                    name: "AnswerNextQuestion",
                    label: "Następne pytanie",
                    placeholder: "1",
                    require: true,
                    type: InputType.DROPDOWN,
                    options: [
                      ...questions
                        .filter(
                          (question) => question.scenario?.toString() === id
                        )
                        .map((question) => ({
                          id: question.id,
                          name: question.content,
                        })),
                      EMPTY_QUESTION,
                    ],
                    value: {
                      id:
                        questions.find(
                          (q) =>
                            q.id ===
                            (answers.find((a) => a.id === answerId)
                              ?.next_question ?? "")
                        )?.id ?? EMPTY_QUESTION.id,
                      name:
                        questions.find(
                          (q) =>
                            q.id ===
                            (answers.find((a) => a.id === answerId)
                              ?.next_question ?? "")
                        )?.content ?? EMPTY_QUESTION.name,
                    },
                  },
                  {
                    name: "AnswerTags",
                    label: "Tagi",
                    type: InputType.TAG,
                    options: tags.map((tag) => ({
                      id: tag.id,
                      name: tag.tag,
                    })),
                    value: answers.find((a) => a.id === answerId)
                      ? answers.find((a) => a.id === answerId)?.tags
                      : [],
                  },
                ]}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ScenarioCreator;
